import Profissional from 'components/Profissional';
import Proposta from 'components/Proposta';

export default function Sobre (){

    return(<div>
        <Profissional />
        <Proposta/>
        </div>
    )
}